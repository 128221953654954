<template>
<section class="login-form">
    <h1 class="form-header uppercase">
        {{ $t("FORM.SIGNIN") }}
    </h1>
    <p class="signin-email">{{ $t("FORM.SIGNING_IN_AS") }} {{ email }}</p>
    <form class="flex-column user-profile-form" novalidate v-on:submit.prevent="onSubmit">
        <section>
            <PasswordInput
                v-model="password.value"
                v-model:invalid="password.invalid"
                v-model:invalidation-reason="password.invalidationReason"
                :label="$t('FORM.PASSWORD_ENTER')"
                placeholder="Enter password..."
                autocomplete="new-password"
                novalidate noconfirm
            />
        </section>
        <section>
            <ButtonElement
                type="submit"
                class="submit-btn"
                :disabled="!canSubmit"
                v-on:click="emailSignIn"
                v-on:keydown.enter="emailSignIn"
            >
                {{ $t("NAVBAR.SIGN_IN") }}
            </ButtonElement>
            <div class="forgot-pw-container">
                <a
                    role="button" tabindex="0" class="link"
                    v-on:click="onForgotPassword"
                    v-on:keydown.enter="onForgotPassword"
                >{{ $t("FORM.FORGOT_PASSWORD") }}</a>
            </div>
        </section>
    </form>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { JBGApi } from '$services/api'
import { useCurrentUser } from '$services/user'
import type { JBGWeb } from '$types/JBGWeb'

import ButtonElement from '$components/ButtonElement.vue'
import PasswordInput from '$components/input/fields/PasswordInput.vue'

export default defineComponent({
    components: {
        PasswordInput,
        ButtonElement
    },

    props: {
        email: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            showPassword: false,
            password: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            currentUser: useCurrentUser()
        }
    },

    computed: {
        canSubmit() {
            return this.password.invalid === false
        }
    },
    methods: {
        clearErrors() {
            this.password.invalid = false
            this.password.invalidationReason = undefined
        },
        async emailSignIn() {
            try {
                const res = await this.$user.emailLogin(this.email, this.password.value)
                if (res.status === 204) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    void this.$router.push(this.localeRoute({
                        path: '/play'
                    }))
                }
            } catch (error) {
                if (error && error instanceof JBGApi.Error) {
                    if (error.httpStatus === 401) {
                        // User is not allowed to do that ..
                        this.$toast.add({
                            id: 'password',
                            type: 'error',
                            text: 'Unauthorized'
                        })
                        // this.password.invalid = true
                        // this.password.invalidationReason = 'unauthorized'
                    } else if (error.code === 2004) {
                        // User hasn't confirmed their email address/account ..
                        // ASKABOUT: should we also resend the email at this point too?
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        void this.$router.push(this.localeRoute({
                            name: 'user-verify'
                        }))
                    }
                } else {
                    this.$toast.add({
                        id: 'password',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            }
        },

        async onForgotPassword() {
            await this.$user.forgotPassword(this.currentUser.state.email)
            const route = `/user/forgotpassword/${this.currentUser.state.email}`
            void this.$router.push({
                path: route
            })
        },

        onSubmit() {
            return false
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.form-header {
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;

    @include mq-xsmall {
        font-size: 26px;
        font-weight: 800;
        line-height: 26px;
    }
}

.submit-btn {
    height: 54px;
    margin-top: 12px;
}

.uppercase {
    text-transform: uppercase;
}

.signin-email {
    font-size: 75%;
    color: var(--neutral-400);
    margin-bottom: 28px;
}

.user-profile-form {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 100%;
    align-items: stretch;
    text-align: left;

    .label {
        color: var(--neutral-50);
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        text-transform: uppercase;
    }

    .input {
        color: var(--neutral-300);
        color-scheme: dark;
        font-size: 18px;
        font-weight: 400;
        height: 56px;
        line-height: 18px;
        margin: 8px 0 20px 0;
        padding: 16px;

        &:active,
        &:focus {
            outline: none;
        }

        &.invalid {
            border-bottom: 2px solid var(--red-300);
        }
    }

    .password-field {
        margin-bottom: 0px;
    }

    .password-toggle-icon {
        cursor: pointer;
        align-self: end;
        position: relative;
        bottom: 36px;
        right: 16px;
    }
}

.forgot-pw-container {
    margin-top: 16px;
    .link {
        cursor: pointer;
    }
}
</style>
