<template>
<section class="login-form">
    <h1 class="form-header uppercase">
        {{ $t('FORM.CREATE_YOUR_ACCOUNT') }}
    </h1>
    <form class="flex-column user-profile-form" novalidate v-on:submit.prevent="onSubmit">
        <FormErrorToolTip v-if="username.invalid && usernameErrText" :error-text="usernameErrText" />
        <label for="username" class="label">{{ $t('FORM.USERNAME') }}</label>
        <input
            id="username" v-model="username.value" type="text"
            class="input"
            :class="{invalid: username.invalid}"
            name="username" maxLength="16" minLength="4"
            pattern="[a-zA-Z0-9_]+"
            autocomplete="username"
            required
            v-on:blur="validateUsername()"
            v-on:keydown="clearTypingTimer(typingTimer)"
            v-on:keyup="startTypingTimer('username')"
        >
        <template v-if="isUserEmailSignup()">
            <FormErrorToolTip v-if="password.invalid && passwordErrText" :error-text="passwordErrText" />
            <label for="password" class="label">{{ $t('FORM.PASSWORD_SET') }}</label>
            <input
                v-if="!showPassword" id="password" v-model="password.value"
                type="password" name="password"
                required class="input password-field"
                autocomplete="new-password"
                :class="{invalid: password.invalid}"
                v-on:blur="validatePassword()"
                v-on:keydown="clearTypingTimer(typingTimer)"
                v-on:keyup="startTypingTimer('password')"
            >
            <input
                v-else id="password" v-model="password.value"
                type="text" name="password"
                required class="input password-field"
                :class="{invalid: password.invalid}"
                v-on:blur="validatePassword()"
                v-on:keydown="clearTypingTimer(typingTimer)"
                v-on:keyup="startTypingTimer('password')"
            >
            <span
                role="button" tabindex="0"
                class="password-toggle-icon" v-on:click="toggleShowPassword"
                v-on:keydown.enter="toggleShowPassword"
            ><i
                id="eyeball-icon"
                class="fa-regular"
                :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"
            /></span>
            <FormErrorToolTip v-if="confirmPassword.invalid && confirmPasswordErrText" :error-text="confirmPasswordErrText" />
            <label for="confirmPassword" class="label">{{ $t('FORM.PASSWORD_SET_CONFIRM') }}</label>
            <input
                v-if="!showPassword" id="confirmPassword" v-model="confirmPassword.value"
                type="password" name="confirmPassword" minLength="6"
                required class="input confirm-password-field"
                autocomplete="new-password"
                :class="{invalid: confirmPassword.invalid}"
                v-on:blur="validateConfirmPassword()"
                v-on:keydown="clearTypingTimer(typingTimer)"
                v-on:keyup="startTypingTimer('confirmPassword')"
            >
            <input
                v-else id="confirmPassword" v-model="confirmPassword.value"
                type="text" name="confirmPassword" minLength="6"
                required class="input confirm-password-field"
                :class="{invalid: confirmPassword.invalid}"
                v-on:blur="validateConfirmPassword()"
                v-on:keydown="clearTypingTimer(typingTimer)"
                v-on:keyup="startTypingTimer('confirmPassword')"
            >
        </template>
        <FormErrorToolTip v-if="dob.invalid && dobErrText" :error-text="dobErrText" />
        <label for="dateOfBirth" class="label">{{ $t('FORM.DOB') }}</label>
        <input
            id="dateOfBirth" v-model="dob.value" type="date"
            :max="new Date().toISOString().split('T')[0]"
            name="dateOfBirth" required class="input uppercase dob-field"
            :class="{invalid: dob.invalid}"
            v-on:blur="validateDateOfBirth()"
            v-on:keydown="clearTypingTimer(typingTimer)"
            v-on:keyup="startTypingTimer('dateOfBirth')"
        >
        <ButtonElement
            type="submit" class="submit-btn" :disabled="!canSubmit"
            v-on:click="createUserAcct"
            v-on:keydown.enter="createUserAcct"
        >
            {{ $t('FORM.CONTINUE') }}
        </ButtonElement>
    </form>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { JBGWeb } from '$types/JBGWeb'
import { useCurrentUser, User, UserApi } from '$services/user'
import ButtonElement from '$components/ButtonElement.vue'
import { JBGApi } from '$services/api'
import FormErrorToolTip from './FormErrorToolTip.vue'

export default defineComponent({
    components: {
        ButtonElement,
        FormErrorToolTip
    },

    props: {
        email: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            // always start with the same avatar by default.
            // users can update this later in their user account settings menu.
            selectedAvatar: 'avatar0',
            showPassword: false,
            showConfirmPassword: false,

            username: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            password: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            confirmPassword: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            dob: { value: '', invalid: false } as JBGWeb.UserFormFieldData,

            typingTimer: <ReturnType<typeof setTimeout> | null> null,

            currentUser: useCurrentUser()
        }
    },

    computed: {
        canSubmit() {
            if (!this.username.invalid && !this.password.invalid && !this.confirmPassword.invalid && !this.dob.invalid) return true
            return false
        },

        usernameErrText(): string | null {
            switch (this.username.invalidationReason) {
            case 'usernameBlank':
                return this.$t('FORM.ERRORS.USERNAME_BLANK')
            case 'usernameLength':
                return this.$t('FORM.ERRORS.USERNAME_LENGTH')
            case 'usernamePattern':
                return this.$t('FORM.ERRORS.USERNAME_PATTERN')
            case 'usernameProfanity':
                return this.$t('FORM.ERRORS.USERNAME_PROFANITY')
            case 'usernameTaken':
                return this.$t('FORM.ERRORS.USERNAME_TAKEN')
            default:
                return null
            }
        },

        passwordErrText(): string | null {
            // do not show error if username has error
            if (this.username.invalid) return null
            switch (this.password.invalidationReason) {
            case 'passwordBlank':
                return this.$t('FORM.ERRORS.PASSWORD_BLANK')
            case 'passwordLength':
                return this.$t('FORM.ERRORS.PASSWORD_LENGTH')
            case 'passwordPattern':
                return this.$t('FORM.ERRORS.PASSWORD_PATTERN')
            default:
                return null
            }
        },

        confirmPasswordErrText(): string | null {
            // do not show error if username or pwd has error
            if (this.username.invalid || this.password.invalid) return null
            switch (this.confirmPassword.invalidationReason) {
            case 'passwordConfirmBlank':
                return this.$t('FORM.ERRORS.PASSWORD_CONFIRM_BLANK')
            case 'passwordsNotMatch':
                return this.$t('FORM.ERRORS.PASSWORD_CONFIRM_NOT_MATCH')
            default:
                return null
            }
        },

        dobErrText(): string | null {
            // do not show error if username or password fields have error
            if (this.username.invalid || this.password.invalid || this.confirmPassword.invalid) return null
            switch (this.dob.invalidationReason) {
            case 'dobBlank':
                return this.$t('FORM.ERRORS.DOB_BLANK')
            case 'dobFuture':
                return this.$t('FORM.ERRORS.DOB_FUTURE')
            case 'dobPast':
                return this.$t('FORM.ERRORS.DOB_TOO_OLD')
            default:
                return null
            }
        }
    },

    watch: {
        showPassword() {
            const eyeballIcon = document.getElementById('eyeball-icon')
            if (!eyeballIcon) return
            if (!this.showPassword) {
                eyeballIcon.classList.remove('fa-eye')
                eyeballIcon.classList.add('fa-regular', 'fa-eye-slash')
            }

            if (this.showPassword) {
                eyeballIcon.classList.remove('fa-eye-slash')
                eyeballIcon.classList.add('fa-regular', 'fa-eye')
            }
        }
    },

    methods: {
        onSubmit() {
            return false
        },

        onSelectAvatar(avatarIndex: string) {
            this.selectedAvatar = avatarIndex
        },

        createUserAcct() {
            if (this.currentUser.getStatus() === User.Status.CreatingBySSO) {
                // if a user got here using Google SSO
                void this.updateUserProfile()
            } else {
                // if a user got here typing in their email
                void this.emailSignUp()
            }
        },

        async emailSignUp() {
            try {
                // eslint-disable-next-line max-len
                await this.$user.emailSignup(this.email, this.password.value, this.confirmPassword.value, this.username.value, this.dob.value)
                // route to verification screen
                void this.$router.push({
                    path: '/user/verify'
                })
            } catch (error) {
                if (error && error instanceof JBGApi.Error) {
                    console.error('saw a JBG error', error.code)
                    // httpStatus == 400 errors
                    switch (error.code) {
                    case 2001: // 2001 - email or pw not set
                        this.password.invalidationReason = 'passwordBlank'
                        if (!this.password.invalid) return
                        this.password.invalid = true
                        break
                    case 2002: // 2002 - passwords don't match
                        this.confirmPassword.invalidationReason = 'passwordsNotMatch'
                        if (!this.confirmPassword.invalid) return
                        this.confirmPassword.invalid = true
                        break
                    default: // error.code: 1000
                        console.error('invalid request:', error)
                    }
                } else {
                    console.error(error)
                    this.$toast.add({
                        id: 'emailsignup',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            }
        },

        async updateUserProfile() {
            // wait a beat to make sure we catch any last minute validation checks
            await new Promise((resolve) => { setTimeout(resolve, 200) })

            if (this.canSubmit) {
                const u = <UserApi.UpdateRequest>{
                    username: this.username.value,
                    avatar: this.selectedAvatar, // TODO: avatar string validation?
                    dob: this.dob.value
                }
                try {
                    await this.$user.update(u)
                    const redirect = sessionStorage.getItem('redirect')
                    if (redirect !== null) {
                        window.location.href = redirect
                    } else {
                        void this.$router.push({ path: '/play' })
                    }
                } catch (err) {
                    if (err && err instanceof JBGApi.Error) {
                        console.error('error updating user profile: ', err)
                    } else {
                        this.$toast.add({
                            id: 'userupdate',
                            type: 'error',
                            text: this.$t('GENERAL.ERROR')
                        })
                    }
                }
            }
        },

        validateUsername() {
            if (!this.username.value) return

            // username is blank
            if (this.username.value.length === 0) {
                this.username.invalid = true
                this.username.invalidationReason = 'usernameBlank'
                return
            }
            // username length invalid
            if (this.username.value.length < 4 || this.username.value.length > 16) {
                this.username.invalid = true
                this.username.invalidationReason = 'usernameLength'
                return
            }
            // username doesn't match pattern
            // only letters, numbers, and underscores allowed.
            const usernameRegex = /^[a-zA-Z0-9_]+$/
            const valid = usernameRegex.test(this.username.value)
            if (!valid) {
                this.username.invalid = true
                this.username.invalidationReason = 'usernamePattern'
            }

            this.$user.checkUserName(this.username.value).catch((err) => {
                this.username.invalid = true
                if (err && err instanceof JBGApi.Error) {
                    if (err.code === JBGApi.UserJBGExists) {
                        this.username.invalidationReason = 'usernameTaken'
                    }
                    if (err.code === JBGApi.UserInappropriateUsername) {
                        this.username.invalidationReason = 'usernameProfanity'
                    }
                } else {
                    this.$toast.add({
                        id: 'usernamecheck',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            })
        },

        validateConfirmPassword() {
            if (!this.confirmPassword.value) return

            // confirm password is blank
            if (this.confirmPassword.value.length === 0) {
                this.confirmPassword.invalid = true
                this.confirmPassword.invalidationReason = 'passwordConfirmBlank'
            }
            // confirm password doesn't match password
            if (this.confirmPassword.value && this.confirmPassword.value !== this.password.value) {
                this.confirmPassword.invalid = true
                this.confirmPassword.invalidationReason = 'passwordsNotMatch'
            }
        },

        // return null if successful or string with failure reason to inform what error text to display
        validatePassword() {
            if (!this.password.value) return

            // password is blank
            if (this.password.value.length === 0) {
                this.password.invalid = true
                this.password.invalidationReason = 'passwordBlank'
            }
            // password length invalid
            if (this.password.value.length < 8) {
                this.password.invalid = true
                this.password.invalidationReason = 'passwordLength'
            }
            // password doesn't match pattern
            // explaination of this regex here: https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito
            // eslint-disable-next-line max-len
            const pwdRegex = /^(?!\s+)(?!.*\s+$)(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
            const valid = pwdRegex.test(this.password.value)
            if (!valid) {
                this.password.invalid = true
                this.password.invalidationReason = 'passwordPattern'
            }
        },

        validateDateOfBirth() {
            if (!this.dob.value) return

            // dob is blank
            if (this.dob.value.length === 0) {
                this.dob.invalid = true
                this.dob.invalidationReason = 'dobBlank'
            }
            // dob is in the future
            if (new Date(this.dob.value) > new Date()) {
                this.dob.invalid = true
                this.dob.invalidationReason = 'dobFuture'
            }
            // dob is too far in the past
            if (new Date(this.dob.value) < new Date('1901')) {
                this.dob.invalid = true
                this.dob.invalidationReason = 'dobPast'
            }
        },

        clearTypingTimer(timer: ReturnType<typeof setTimeout> | null) {
            if (!timer) return
            clearTimeout(timer)
        },

        startTypingTimer(fieldName: string) {
            this.clearTypingTimer(this.typingTimer)
            const DONE_TYPING_INTERVAL = 1500
            switch (fieldName) {
            case 'username':
                this.username.invalid = false
                this.typingTimer = setTimeout(this.validateUsername, DONE_TYPING_INTERVAL)
                break
            case 'password':
                this.password.invalid = false
                this.typingTimer = setTimeout(this.validatePassword, DONE_TYPING_INTERVAL)
                break
            case 'confirmPassword':
                this.confirmPassword.invalid = false
                this.typingTimer = setTimeout(this.validateConfirmPassword, DONE_TYPING_INTERVAL)
                break
            case 'dateOfBirth':
            case 'dob':
                this.dob.invalid = false
                this.typingTimer = setTimeout(this.validateDateOfBirth, DONE_TYPING_INTERVAL)
                break
            default:
                console.warn('no field name provided.')
                break
            }
        },

        toggleShowPassword() {
            this.showPassword = !this.showPassword
        },

        isUserEmailSignup() {
            return this.currentUser.getStatus() === User.Status.CreatingByEmail
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.form-header {
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;

    @include mq-xsmall {
        font-size: 26px;
        font-weight: 800;
        line-height: 26px;
    }
}

.submit-btn {
    height: 54px;
    margin-top: 12px;
}

.uppercase {
    text-transform: uppercase;
}

.user-profile-form {
    align-items: stretch;
    text-align: left;

    .label {
        color: var(--neutral-50);
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        text-transform: uppercase;
    }

    .input {
        color: var(--neutral-300);
        color-scheme: dark;
        font-size: 18px;
        font-weight: 400;
        height: 56px;
        line-height: 18px;
        margin: 8px 0 20px 0;
        padding: 16px;

        &:active, &:focus {
            outline: none;
        }

        &.invalid {
            border-bottom: 2px solid var(--red-300);
        }

        &.dob-field {
            font-size: 16px;
            line-height: 16px;

            // TODO: add spacing around forward slashes in date placeholder
        }
    }

    .password-field {
        margin-bottom: 0px;
    }

    .password-toggle-icon {
        cursor: pointer;
        align-self: end;
        position: relative;
        bottom: 36px;
        right: 16px;
    }
}
</style>
